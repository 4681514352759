<template>
  <div class="button-group">
    <tooltip-button
      icon="history"
      text="History"
      color="default"
      :dark="false"
      @click="$refs.entityLogDialog.open = true"
      classNames="ml-3"
      position="top"
      size="small"
    />
    <tooltip-button
      icon="logout"
      text="Austritt"
      color="default"
      :dark="false"
      @click="openResignDialog()"
      classNames="ml-3"
      position="top"
      size="small"
    />
    <tooltip-button
      icon="vpn_key"
      text="Passwort zurücksetzen"
      color="default"
      :dark="false"
      @click="resetPasswordDialog = true"
      classNames="ml-3"
      position="top"
      size="small"
    />
    <tooltip-button
      icon="save"
      text="Speichern"
      :color="canBeSaved ? 'primary' : 'default'"
      :dark="false"
      @click="$emit('saved')"
      classNames="ml-3"
      position="top"
      size="medium"
      :disabled="!canBeSaved"
    />
    <entity-log-dialog
      v-if="user"
      entity-name="users"
      :entity-id="user.id"
      ref="entityLogDialog"
    />
    <resign-dialog ref="resignDialog" @resigned="$emit('resigned')" />
    <v-dialog v-model="resetPasswordDialog" width="400">
      <v-card>
        <v-card-text class="pt-5">
          Soll das Passwort wirklich zurückgesetzt werden?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="resetPasswordDialog = false">
            Nein
          </v-btn>
          <v-btn color="primary" text @click="resetPassword()">
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EntityLogDialog from "@/components/dialogs/EntityLogDialog";
import TooltipButton from "@/components/forms/TooltipButton";
import ResignDialog from "@/components/dialogs/ResignDialog";
import moment from "moment";
import { mapMutations } from "vuex";

export default {
  name: "UserButtonGroup",

  components: {
    EntityLogDialog,
    TooltipButton,
    ResignDialog
  },

  props: {
    user: {
      type: Object,
      required: true
    },
    canBeSaved: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    resetPasswordDialog: false
  }),

  methods: {
    /**
     * Open resign dialog.
     */
    openResignDialog() {
      this.$refs.resignDialog.open = true;
      this.$refs.resignDialog.resignsAt = this.user.resigns_at
        ? moment(this.user.resigns_at)
        : null;
      this.$refs.resignDialog.userId = this.user.id;
    },

    /**
     * Reset the user's password.
     */
    resetPassword() {
      this.$api.http
        .post(`auth/reset-password`, { email: this.user.email })
        .then(response => {
          this.resetPasswordDialog = null;
          this.$toast.success(
            "Das Passwort wurde zurückgesetzt und der Benutzer informiert."
          );
        })
        .catch(error => {
          this.resetPasswordDialog = null;
          this.$toast.error("Das Passwort konnte nicht zurückgesetzt werden.");
        });
    }
  }
};
</script>

<style scoped></style>
