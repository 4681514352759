<template>
  <div class="address">
    <tooltip-button
      v-if="address"
      icon="save"
      text="Speichern"
      :color="canBeSaved ? 'primary' : 'default'"
      :dark="false"
      @click="updateAddress()"
      classNames="float-right"
      position="top"
      size="medium"
      :disabled="!canBeSaved"
    />
    <template v-if="withTitle">
      <h3 class="mt-2" v-if="type === 'primary'">Hauptaddresse</h3>
      <h3 class="mt-2" v-if="type === 'billing'">Rechnungsadresse</h3>
    </template>
    <template v-if="address">
      <v-form v-model="valid" @submit.prevent="save()">
        <v-row>
          <v-col cols="6">
            <h2 class="mb-6 font-weight-medium grey--text text--darken-3">
              Anschrift
            </h2>
            <v-text-field
              outlined
              dense
              v-model="address.company"
              label="Firma"
              type="text"
              placeholder=" "
              :maxlength="255"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="address.street"
              label="Strasse"
              type="text"
              placeholder=" "
              :maxlength="255"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="address.pobox"
              label="Postfach"
              type="text"
              placeholder=" "
              :maxlength="100"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="address.zip"
              label="PLZ"
              type="text"
              placeholder=" "
              :maxlength="20"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="address.city"
              label="Ort"
              type="text"
              placeholder=" "
              :maxlength="255"
            ></v-text-field>
            <v-select
              outlined
              dense
              :items="countries"
              v-model="address.country"
              label="Land"
              item-text="name"
              item-value="value"
            ></v-select>
          </v-col>
          <v-col v-if="type === 'primary'" cols="6">
            <h2 class="mb-6 font-weight-medium grey--text text--darken-3">
              Unternehmen
            </h2>
            <v-text-field
              outlined
              dense
              v-model="address.company"
              label="Name"
              type="text"
              placeholder=" "
              :maxlength="255"
            ></v-text-field>
            <v-select
              outlined
              dense
              :items="legalForms"
              v-model="address.legal_form"
              label="Rechtsform"
              item-text="name"
              item-value="value"
            ></v-select>
            <v-text-field
              outlined
              dense
              v-model="address.vat_number"
              label="MWST-Nr. / UID-Nr."
              type="text"
              placeholder=" "
              :maxlength="50"
            ></v-text-field>
            <!-- <v-checkbox
              v-model="address.has_email_invoice"
              label="Rechnung per E-Mail"
            ></v-checkbox> -->
            <h2 class="mt-4 mb-6 font-weight-medium grey--text text--darken-3">
              Kontakt
            </h2>
            <v-text-field
              outlined
              dense
              v-model="address.phone_private"
              label="Telefon Privat"
              type="text"
              placeholder=" "
              :maxlength="30"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="address.phone_work"
              label="Telefon Arbeit"
              type="text"
              placeholder=" "
              :maxlength="30"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="address.phone_mobile"
              label="Telefon Mobile"
              type="text"
              placeholder=" "
              :maxlength="30"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="address.website"
              label="Website"
              type="text"
              placeholder=" "
              :maxlength="255"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-else>
      <p>
        Es wurde noch keine
        <span v-if="type === 'primary'">Hauptaddresse</span>
        <span v-if="type === 'billing' && this.entity.primary_address">
          Rechnungsadresse
        </span>
        <span v-if="type === 'billing' && !this.entity.primary_address">
          Haupt- und Rechnungsadresse
        </span>
        erfasst.
      </p>
      <v-btn
        color="default"
        class="elevation-0"
        @click="dialog = true"
        :disabled="!isCreatable"
      >
        Jetzt erstellen
      </v-btn>
    </template>

    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-card-text class="pt-5">
          Soll die Addresse wirklich erstellt werden?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="dialog = false">
            Nein
          </v-btn>
          <v-btn color="primary" text @click="createAddress()">
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TooltipButton from "./TooltipButton.vue";

export default {
  components: { TooltipButton },
  props: {
    id: {
      required: true
    },
    type: {
      required: true,
      type: String
    },
    entity: {
      required: true
    },
    entityName: {
      required: true,
      type: String
    },
    withTitle: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    dialog: false,
    address: null,
    valid: false,
    requireRules: [v => !!v || "Muss ausgefüllt sein"],
    dirty: false,
    init: false
  }),

  computed: {
    ...mapState("app", ["configs"]),

    canBeSaved() {
      return this.valid && this.dirty && this.address;
    },

    isCreatable() {
      return (
        this.type === "primary" ||
        (this.type === "billing" && this.entity.primary_address)
      );
    },

    legalForms() {
      return Object.keys(this.configs.legal_forms).map(key => ({
        name: this.configs.legal_forms[key],
        value: key
      }));
    },

    countries() {
      return Object.keys(this.configs.countries).map(key => ({
        name: this.configs.countries[key],
        value: key
      }));
    }
  },

  watch: {
    address: {
      handler(value) {
        if (this.init) {
          this.dirty = true;
        }
        this.init = true;
      },
      deep: true
    }
  },

  created() {
    if (this.id) {
      this.fetchAddress();
    }
  },

  methods: {
    /**
     * Fetch address from server
     */
    fetchAddress() {
      this.$api.http.get(`backoffice/addresses/${this.id}`).then(response => {
        this.address = response.data;
      });
    },

    /**
     * Create new address.
     */
    createAddress() {
      let payload = { type: this.type };
      payload[this.entityName + "_id"] = this.entity.id;
      this.$api.http
        .post(`backoffice/addresses`, payload)
        .then(response => {
          this.address = response.data;
          this.dialog = false;
          this.$emit("created");
        })
        .catch(error => {
          this.$toast.error("Die Adresse konnte nicht erstellt werden.");
        });
    },

    /**
     * Update address.
     */
    updateAddress() {
      this.$api.http
        .put(`backoffice/addresses/${this.address.id}`, this.address)
        .then(response => {
          this.$toast.success("Die Adresse wurde aktualisiert.");
          this.dirty = false;
        })
        .catch(error => {
          this.$toast.error("Die Adresse konnte nicht gespeichert werden.");
        });
    }
  }
};
</script>

<style lang="scss">
.address {
}
</style>
