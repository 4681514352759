<template>
  <div class="siport-widget">
    <v-row>
      <v-col cols="4">
        <v-card flat class="pa-3">
          <v-card-title>
            Profil
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="auto" class="pt-0">
                <v-switch
                  class="mt-1"
                  v-model="user.is_siport_permanent"
                  flat
                  :label="`Dauerhaft gespeichert`"
                ></v-switch>
              </v-col>
              <v-col class="py-0">
                <popover
                  text="Benutzerprofil wird dauerhaft in SIPORT gespeichert."
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <h3 class="black--text">
                  Badgenummer
                </h3>
                <p>{{ user.badge_number ? user.badge_number : "Keine" }}</p>
              </v-col>
              <v-col cols="auto" class="py-0">
                <v-btn @click="openEditBadgeDialog()" class="elevation-0 mb-4">
                  Bearbeiten
                </v-btn>
                <v-btn
                  @click="openPrintBadgeDialog()"
                  class="elevation-0 mb-4 ml-3"
                >
                  <v-icon class="mr-2">print</v-icon>
                  Drucken
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="black--text">
                  Standardprofile
                </h3>
                <ul class="mb-4" v-if="siportProfiles.length > 0">
                  <li v-for="(profile, index) in siportProfiles" :key="index">
                    {{ profile }}
                  </li>
                </ul>
                <div v-else class="mb-2">
                  Keine Profile gesetzt.
                </div>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  @click="openBadgesDialog()"
                  :disabled="!user.badge_number"
                  class="elevation-0"
                >
                  Verwalten
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col offset="1" cols="4" v-if="parkingStats">
        <v-card flat class="pa-3">
          <v-card-title>
            Parking
          </v-card-title>
          <v-card-text>
            <v-text-field
              dense
              outlined
              v-model="user.car_number"
              label="Autonummer (aktiv)"
              type="text"
              :maxlength="255"
            ></v-text-field>
            <v-btn
              color="default"
              class="float-right ml-4"
              text
              @click="swapCarNumber()"
              :disabled="!user.car_number_alt"
            >
              <v-icon class="mr-2">swap_vert</v-icon>
              Wechseln
            </v-btn>
            <v-text-field
              dense
              outlined
              v-model="user.car_number_alt"
              label="Wechselnummer"
              type="text"
              :maxlength="255"
            ></v-text-field>
            <div class="float-right">
              <v-chip
                label
                :color="
                  parkingStats.total_inhouse - parkingStats.used_inhouse < 5
                    ? 'warning'
                    : 'success'
                "
              >
                {{ parkingUsedInhouse + " von " + parkingStats.total_inhouse }}
              </v-chip>
            </div>
            <v-switch
              class="mt-0"
              v-model="hasParking"
              flat
              :label="`Besitzt Parkplatz`"
              :disabled="
                !hasParking &&
                  parkingUsedInhouse === parseInt(parkingStats.total_inhouse)
              "
            ></v-switch>
            <v-select
              outlined
              dense
              :items="availableParkingProfiles"
              v-model="parkingProfile"
              label="Profil"
              item-text="name"
              item-value="value"
              :disabled="!hasParking"
            ></v-select>
            <v-row>
              <v-col>
                <DatePicker
                  v-model="parkingFrom"
                  label="Von"
                  :disabled="!hasParking"
                />
              </v-col>
              <v-col>
                <DatePicker
                  v-model="parkingUntil"
                  label="Bis"
                  :disabled="!hasParking"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <edit-badge-dialog ref="editBadgeDialog" @saved="$emit('refetch')" />
    <print-badge-dialog ref="printBadgeDialog" @printed="$emit('refetch')" />
  </div>
</template>

<script>
import EditBadgeDialog from "@/components/dialogs/EditBadgeDialog";
import PrintBadgeDialog from "@/components/dialogs/PrintBadgeDialog";
import BadgesDialog from "@/views/dialogs/BadgesDialog";
import Popover from "../Popover";
import DatePicker from "../forms/DatePicker";
import { mapMutations, mapState } from "vuex";
import moment from "moment";

export default {
  name: "SiportWidget",

  components: {
    EditBadgeDialog,
    PrintBadgeDialog,
    DatePicker,
    Popover
  },

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    parkingStats: null,
    parkingUsedWithoutCurrent: null
  }),

  computed: {
    ...mapState("app", ["configs", "fullscreenDialogComponentState"]),

    user: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },

    siportProfiles() {
      return this.user.siport_profiles
        ? this.user.siport_profiles
            .map(profile => {
              const found = this.configs.siport_profiles.find(
                p => p.profile === profile
              );
              return found ? found.name : null;
            })
            .filter(x => !!x)
        : [];
    },

    hasParking: {
      get() {
        return (
          this.user && !!this.user.parking_from && !!this.user.permanent_profile
        );
      },
      set(value) {
        if (value) {
          this.user.parking_from = moment();
          this.user.permanent_profile = this.availableParkingProfiles[0].value;
        } else {
          this.user.parking_from = null;
          this.user.permanent_profile = null;
        }
      }
    },

    parkingUsedInhouse() {
      return this.hasParking
        ? this.parkingUsedWithoutCurrent + 1
        : this.parkingUsedWithoutCurrent;
    },

    parkingFrom: {
      get() {
        return this.user.parking_from ? moment(this.user.parking_from) : null;
      },
      set(value) {
        this.user.parking_from = value
          ? value.format("YYYY-MM-DD HH:mm:ss")
          : null;
      }
    },

    parkingUntil: {
      get() {
        return this.user.parking_until ? moment(this.user.parking_until) : null;
      },
      set(value) {
        this.user.parking_until = value
          ? value.format("YYYY-MM-DD HH:mm:ss")
          : null;
      }
    },

    parkingProfile: {
      get() {
        return this.user.permanent_profile ?? null;
      },
      set(value) {
        this.user.permanent_profile = value;
      }
    },

    availableParkingProfiles() {
      return Object.keys(this.configs.parking_profiles).map(key => {
        return {
          name: this.configs.parking_profiles[key],
          value: key
        };
      });
    }
  },

  watch: {
    hasParking(value) {
      if (this.user && !value) {
        this.user.parking_from = null;
        this.user.parking_until = null;
        this.user.permanent_profile = null;
      }
    },

    async fullscreenDialogComponentState(value) {
      const responseUser = await this.fetchUser();
      this.user = responseUser.data;
    }
  },

  async mounted() {
    const response = await this.fetchParkingStats();
    this.parkingStats = response.data;
    this.parkingUsedWithoutCurrent = this.user.parking_from
      ? this.parkingStats.used_inhouse - 1
      : this.parkingStats.used_inhouse;
  },

  methods: {
    ...mapMutations("app", [
      "setFullscreenDialogComponent",
      "setFullscreenDialogConfig"
    ]),

    /**
     * Fetch user from server
     */
    fetchUser() {
      return this.$api.http.get(`backoffice/users/${this.user.id}`);
    },

    /**
     * Fetch parking stats from server.
     */
    fetchParkingStats() {
      return this.$api.http.get("backoffice/settings/parking-stats");
    },

    /**
     * Open the change scopes dialog.
     */
    openEditBadgeDialog() {
      this.$refs.editBadgeDialog.open = true;
      this.$refs.editBadgeDialog.badgeNumber = this.user.badge_number;
      this.$refs.editBadgeDialog.userId = this.user.id;
    },

    /**
     * Open print badge dialog
     */
    openPrintBadgeDialog() {
      this.$refs.printBadgeDialog.open = true;
      this.$refs.printBadgeDialog.user = this.user;
    },

    /**
     * Open the badges dialog.
     */
    openBadgesDialog() {
      this.setFullscreenDialogComponent(BadgesDialog);
      this.setFullscreenDialogConfig({ activeBadge: this.user.badge_number });
    },

    /**
     * Swap car numbers.
     */
    swapCarNumber() {
      const carNumberAlt = this.user.car_number_alt;
      if (!carNumberAlt) {
        return;
      }
      this.user.car_number_alt = this.user.car_number;
      this.user.car_number = carNumberAlt;
    }
  }
};
</script>

<style scoped></style>
