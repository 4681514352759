<template>
  <div class="details-widget">
    <v-row>
      <v-col cols="5">
        <v-card flat class="pa-3">
          <v-card-title>
            Eigenschaften
          </v-card-title>
          <v-card-text>
            <v-select
              dense
              outlined
              :items="genders"
              v-model="user.gender"
              label="Geschlecht"
              item-text="name"
              item-value="value"
            ></v-select>
            <v-text-field
              outlined
              dense
              v-model="user.first_name"
              label="Vorname"
              type="text"
              :maxlength="255"
              :rules="textRules"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="user.last_name"
              label="Nachname"
              type="text"
              :maxlength="255"
              :rules="textRules"
            ></v-text-field>
            <v-tooltip v-if="user.email_verified_at" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  x-large
                  class="float-right ml-2"
                  color="success"
                >
                  how_to_reg
                </v-icon>
              </template>
              <span>
                Verifiziert am
                {{ user.email_verified_at | formatDateTime }}
              </span>
            </v-tooltip>
            <v-tooltip v-else top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  x-large
                  class="float-right ml-2"
                  color="error"
                >
                  person
                </v-icon>
              </template>
              <span>Nicht verifiziert</span>
            </v-tooltip>
            <v-text-field
              dense
              outlined
              v-model="user.email"
              label="E-Mail"
              type="text"
              :maxlength="255"
              :rules="emailRules"
              placeholder=" "
            ></v-text-field>
            <v-card color="grey lighten-4 pa-3" elevation="0">
              <v-row>
                <v-col class="pt-1">
                  <h3 class="font-weight-medium grey--text text--darken-3">
                    Benutzergruppe
                  </h3>
                </v-col>
                <v-col cols="auto" class="pt-1">
                  <scope-group-label :user="user" small />
                </v-col>
                <v-col cols="auto" class="pt-0">
                  <tooltip-button
                    icon="edit"
                    text="Wechseln"
                    :color="'grey lighten-2'"
                    :dark="false"
                    @click="openChangeScopesDialog()"
                    classNames="elevation-0"
                    position="top"
                    size="x-small"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-1 pb-0">
                  <h3 class="font-weight-medium grey--text text--darken-3">
                    Mandant
                  </h3>
                </v-col>
                <v-col cols="auto" class="pt-1 pb-0">
                  <v-chip color="primary" dark label small>
                    {{ user.tenant.name }}
                  </v-chip>
                </v-col>
                <v-col cols="auto" class="pt-0 pb-0">
                  <tooltip-button
                    icon="edit"
                    text="Wechseln"
                    :color="'grey lighten-2'"
                    :dark="false"
                    @click="openChangeTenantDialog()"
                    classNames="elevation-0"
                    position="top"
                    size="x-small"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card flat class="pa-3">
          <v-card-title>
            Informationen für HR
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="py-0">
                <v-row>
                  <v-col cols="auto" class="pt-0">
                    <v-switch
                      class="mt-1"
                      v-model="user.is_member_inhouse"
                      flat
                      :label="`Ist Mieter(in)`"
                    ></v-switch>
                    <v-switch
                      class="mt-0"
                      v-model="user.is_hr_manager"
                      flat
                      :label="`Ist HR-Verantwortliche(r)`"
                    ></v-switch>
                  </v-col>
                  <v-col class="py-0">
                    <popover
                      text="Mieter haben Rabatte, bezahlen wahlweise auf Rechnung und können Parkplätze unabhängig von einer Raumbuchung tätigen."
                    />
                  </v-col>
                </v-row>
                <v-alert
                  v-if="showInhouseInconsistencyHint > 0"
                  dense
                  dark
                  color="info"
                  style="font-size: 14px;"
                >
                  <template v-if="showInhouseInconsistencyHint === 1">
                    Es wurde eine Inkonsistenz in den Berechtigungen
                    festgestellt. Mieter müssen mindestens in der Gruppe "Portal
                    Mieter" sein.
                  </template>
                  <template v-if="showInhouseInconsistencyHint === 2">
                    Es wurde eine Inkonsistenz in den Berechtigungen
                    festgestellt. Nicht-Mietern sollte immer die Gruppe "Portal
                    Extern" zugewiesen werden.
                  </template>
                </v-alert>
                <v-text-field
                  dense
                  outlined
                  v-model="user.staff_number"
                  label="Personalnummer"
                  type="text"
                  :maxlength="255"
                  :disabled="!user.is_member_inhouse"
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  v-model="user.cost_center"
                  label="Kostenstelle"
                  type="text"
                  :maxlength="255"
                  :disabled="!user.is_member_inhouse"
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  v-model="user.department"
                  label="Abteilung"
                  type="text"
                  :maxlength="255"
                  :disabled="!user.is_member_inhouse"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card flat class="px-3">
          <v-card-title>
            Mitgliedschaften
          </v-card-title>
          <v-card-text>
            <v-row class="py-0">
              <v-row>
                <v-col cols="auto" class="pt-4 pb-0">
                  <v-switch
                    class="my-0"
                    v-model="user.is_member_wika"
                    flat
                    :label="`Ist WiKa-Mitglied`"
                  ></v-switch>
                </v-col>
                <v-col class="pb-0">
                  <v-text-field
                    dense
                    outlined
                    v-model="user.wika_pass_number"
                    label="WiKa-Mitgliedsnummer"
                    type="text"
                    :maxlength="100"
                    placeholder=" "
                    :disabled="!user.is_member_wika"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" class="pt-4 pb-0">
                  <WikaPassLabel :user="user" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto" class="pt-4">
                  <v-switch
                    class="mt-0"
                    v-model="user.is_member_pass"
                    flat
                    :label="`Besitzt HDW-Pass`"
                  ></v-switch>
                </v-col>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    v-model="user.hdw_pass_number"
                    label="HDW Passnummer"
                    type="text"
                    :maxlength="14"
                    placeholder=" "
                    :disabled="!user.is_member_pass"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card flat class="pa-3">
          <v-card-title>
            Profilbild
          </v-card-title>
          <v-card-text>
            <image-upload
              v-model="user.avatar"
              :url="`backoffice/users/${user.id}/avatar`"
              :ratioW="25"
              :ratioH="35"
              @changed="$emit('refetch')"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <change-scopes-dialog ref="changeScopesDialog" @saved="$emit('refetch')" />
    <change-tenant-dialog ref="changeTenantDialog" @saved="$emit('refetch')" />
  </div>
</template>

<script>
import ChangeScopesDialog from "@/components/dialogs/ChangeScopesDialog";
import ChangeTenantDialog from "@/components/dialogs/ChangeTenantDialog";
import ScopeGroupLabel from "@/components/labels/ScopeGroupLabel";
import TooltipButton from "../forms/TooltipButton";
import Popover from "../Popover";
import { mapState } from "vuex";
import ImageUpload from "../images/ImageUpload";
import WikaPassLabel from "../labels/WikaPassLabel";

export default {
  name: "DetailsWidget",

  components: {
    WikaPassLabel,
    ImageUpload,
    ChangeScopesDialog,
    ChangeTenantDialog,
    ScopeGroupLabel,
    TooltipButton,
    Popover
  },

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    textRules: [v => !!v || "Muss ausgefüllt sein"],
    emailRules: [
      v =>
        (!!v &&
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          )) ||
        "Ungültige E-Mail."
    ],
    showInhouseInconsistencyHint: 0
  }),

  computed: {
    ...mapState("app", ["configs"]),

    user: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },

    genders() {
      return Object.keys(this.configs.genders).map(key => ({
        name: this.configs.genders[key],
        value: key
      }));
    }
  },

  watch: {
    "user.is_member_inhouse": {
      handler(value) {
        this.checkUserGroupInconsistency();
      }
    },

    "user.group": {
      handler(value) {
        this.checkUserGroupInconsistency();
      }
    }
  },

  mounted() {
    this.checkUserGroupInconsistency();
  },

  methods: {
    /**
     * Check for user group inconsistency.
     */
    checkUserGroupInconsistency() {
      if (this.user) {
        const isMemberInhouse = this.user.is_member_inhouse;
        const hasPortalInhouse = this.user.scopes
          .map(scope => scope.id)
          .includes("portal-inhouse");
        const noPermission = this.user.scopes
          .map(scope => scope.id)
          .includes("no-permission");
        if (isMemberInhouse && !hasPortalInhouse && !noPermission) {
          this.showInhouseInconsistencyHint = 1;
        } else if (!isMemberInhouse && hasPortalInhouse) {
          this.showInhouseInconsistencyHint = 2;
        } else {
          this.showInhouseInconsistencyHint = 0;
        }
      }
    },

    /**
     * Open the change scopes dialog.
     */
    openChangeScopesDialog() {
      this.$refs.changeScopesDialog.open = true;
      this.$refs.changeScopesDialog.group = this.user.group.id;
      this.$refs.changeScopesDialog.user = this.user;
    },
    /**
     * Open the change tenants dialog.
     */
    openChangeTenantDialog() {
      this.$refs.changeTenantDialog.open = true;
      this.$refs.changeTenantDialog.tenantId = this.user.tenant.id;
      this.$refs.changeTenantDialog.user = this.user;
    }
  }
};
</script>

<style lang="scss">
.details-widget {
}
</style>
