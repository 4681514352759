<template>
  <v-dialog v-model="open" scrollable width="500">
    <v-card>
      <v-card-title class="primary white--text">
        <span class="subtitle-1">Rechte verwalten</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <v-select
          outlined
          dense
          :items="options"
          v-model="group"
          label="Gruppe"
          item-text="name"
          item-value="value"
          :rules="textRules"
        ></v-select>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="abort()">
          Abbrechen
        </v-btn>
        <v-btn color="primary" text @click="save()">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    open: false,
    user: null,
    group: null,
    textRules: [v => !!v || "Muss ausgefüllt sein"]
  }),

  computed: {
    ...mapState("app", ["configs"]),

    options() {
      return this.configs.scope_groups
        ? Object.keys(this.configs.scope_groups).map(key => ({
            name: this.configs.scope_groups[key].name,
            value: key
          }))
        : [];
    }
  },

  methods: {
    /**
     * Delete entity.
     */
    save() {
      this.$api.http
        .put(`backoffice/users/${this.user.id}/scopes`, { group: this.group })
        .then(response => {
          this.$emit("saved");
          this.open = false;
          this.group = null;
          this.user = null;
        })
        .catch(error => {
          this.$toast.error("Gruppe konnte nicht geändert werden.");
        });
    },

    /**
     * Abort.
     */
    abort() {
      this.open = false;
      this.group = null;
      this.user = null;
    }
  }
};
</script>

<style lang="scss">
table.groups {
  margin-top: 10px;
  width: 100%;
  border-collapse: collapse;

  tr td {
    padding: 2px 4px;
    border: 1px solid #eee;
    font-size: 12px;
    color: #666;
  }
}
</style>
