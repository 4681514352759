<template>
  <div class="users-edit mt-4 ml-8 mr-8">
    <v-container fluid v-if="user">
      <v-form v-model="valid" @submit.prevent="save()">
        <v-row>
          <v-col cols="auto">
            <h1 class="display-1 mt-2">
              {{ user.first_name }} {{ user.last_name }}
            </h1>
          </v-col>
          <v-col cols="auto">
            <v-chip v-if="!user.is_locked" color="success" label class="mt-3">
              Aktiv
            </v-chip>
            <v-chip v-else color="error" label class="mt-3">
              Gesperrt
            </v-chip>
          </v-col>
          <v-col>
            <v-chip
              v-if="user.is_siport_permanent"
              color="success"
              label
              class="mt-3"
            >
              Permanent
            </v-chip>
            <v-chip v-else color="warning" label class="mt-3">
              Temporär
            </v-chip>
          </v-col>
          <v-col cols="auto" v-if="resignsAt">
            <v-alert
              color="warning"
              dark
              dense
              class="mb-0"
              style="margin-top: 10px;"
            >
              Austritt am {{ resignsAt }}
            </v-alert>
          </v-col>
          <v-col cols="auto" class="pr-0">
            <div style="width:110px;">
              <v-switch
                v-model="isActive"
                :label="`${isActive ? 'Aktiv' : 'Gesperrt'} `"
              ></v-switch>
            </div>
          </v-col>
          <v-col cols="auto pl-0 pt-6">
            <popover
              text="Inaktive Benutzer sind einerseits im SIPORT gesperrt und andererseits wird ihnen der Zugang zum Portal oder Backoffice verwehrt."
            />
          </v-col>
          <v-col cols="auto">
            <user-button-group
              :user="user"
              :can-be-saved="canBeSaved"
              @saved="save"
              @resigned="refetch"
            />
          </v-col>
        </v-row>
        <v-card outlined elevation="0" class="mb-5">
          <v-tabs v-model="tab" grow background-color="grey lighten-3">
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">
              <v-icon class="mr-2">person</v-icon>
              Details
            </v-tab>
            <v-tab href="#siport">
              <v-icon class="mr-2">badge</v-icon>
              Zutrittskontrolle (SIPORT)
            </v-tab>
            <v-tab href="#mainaddress">
              <v-icon class="mr-2">house</v-icon>
              Hauptadresse
            </v-tab>
            <v-tab href="#invoiceaddress">
              <v-icon class="mr-2">account_balance</v-icon>
              Rechnungsadresse
            </v-tab>
            <v-tab
              href="#tenantaddress"
              v-if="user.tenant.short_name !== 'default'"
            >
              <v-icon class="mr-2">apartment</v-icon>
              Adresse des Mandanten
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item eager value="details">
              <div :key="addressUpdate">
                <details-widget v-model="user" @refetch="refetch" />
              </div>
            </v-tab-item>
            <v-tab-item eager value="siport">
              <div :key="addressUpdate">
                <siport-widget v-model="user" @refetch="refetch" />
              </div>
            </v-tab-item>
            <v-tab-item eager value="mainaddress">
              <div :key="addressUpdate">
                <v-card flat class="pa-3">
                  <v-card-text>
                    <address-form
                      :id="
                        user.primary_address ? user.primary_address.id : null
                      "
                      :type="configs.address_types.primary"
                      :entity="user"
                      :entity-name="'user'"
                      @created="setUser()"
                    />
                  </v-card-text>
                </v-card>
              </div>
            </v-tab-item>
            <v-tab-item eager value="invoiceaddress">
              <v-card flat class="pa-3">
                <v-card-text>
                  <address-form
                    :id="user.billing_address ? user.billing_address.id : null"
                    :type="configs.address_types.billing"
                    :entity="user"
                    :entity-name="'user'"
                    @created="setUser()"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item eager value="tenantaddress">
              <v-card flat class="pa-3">
                <v-card-text>
                  <address-form
                    :id="user.tenant_address ? user.tenant_address.id : null"
                    :type="configs.address_types.primary"
                    :entity="user.tenant"
                    :entity-name="'tenant'"
                    @created="setUser()"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import UserButtonGroup from "../../components/users/UserButtonGroup";
import Popover from "../../components/Popover";
import DetailsWidget from "../../components/users/DetailsWidget";
import SiportWidget from "../../components/users/SiportWidget";
import AddressForm from "../../components/forms/AddressForm";

export default {
  name: "Edit",

  components: {
    SiportWidget,
    Popover,
    UserButtonGroup,
    DetailsWidget,
    AddressForm
  },

  data: () => ({
    tab: null,
    user: null,
    valid: false,
    dirty: false,
    addressUpdate: 0
  }),

  computed: {
    ...mapState("app", ["configs"]),

    userId() {
      return this.$route.params.id;
    },

    resignsAt() {
      return this.user && this.user.resigns_at
        ? moment(this.user.resigns_at, "YYYY-MM-DD", "de").format(
            "dddd, DD.MM.YYYY"
          )
        : null;
    },

    isActive: {
      get() {
        return this.user && !this.user.is_locked;
      },
      set(value) {
        this.user.is_locked = !value;
      }
    },

    canBeSaved() {
      return this.valid && this.dirty && !!this.user;
    }
  },

  watch: {
    user: {
      handler(value) {
        if (this.init) {
          this.dirty = true;
        }
        this.init = true;
      },
      deep: true
    }
  },

  async created() {
    const response = await this.fetchUser();
    this.user = response.data;
  },

  methods: {
    /**
     * Fetch user from server
     */
    fetchUser() {
      return this.$api.http.get(`backoffice/users/${this.userId}`);
    },

    /**
     * Save user.
     */
    save() {
      let user = this.user;
      delete user.scopes;
      this.$api.http
        .put(`backoffice/users/${this.user.id}`, user)
        .then(response => {
          this.$toast.success("Der Benutzer wurde aktualisiert.");
          this.user = response.data;
          this.$nextTick(() => {
            this.dirty = false;
            this.addressUpdate++;
          });
        })
        .catch(error => {
          if (error.response.data && error.response.data.code) {
            const code = error.response.data.code;
            if (code === 101) {
              this.$toast.error(
                "Es existiert bereits ein Benutzer mit dieser E-Mail."
              );
            } else if (code === 102) {
              this.$toast.error(
                "Es existiert bereits ein Benutzer mit dieser Badgenummer."
              );
            } else {
              this.$toast.error("Der Benutzer konnte nicht gespeichert werden");
            }
          } else {
            if (
              Object.keys(error.response.data.errors).indexOf("car_number") >= 0
            ) {
              this.$toast.error("Diese Autonummer ist bereits vergeben.");
            } else if (
              Object.keys(error.response.data.errors).indexOf(
                "car_number_alt"
              ) >= 0
            ) {
              this.$toast.error("Diese Wechselnummer ist bereits vergeben.");
            } else {
              this.$toast.error(
                "Der Benutzer konnte nicht gespeichert werden 1."
              );
            }
          }
        });
    },

    /**
     * Changed badge number.
     */
    async refetch() {
      const response = await this.fetchUser();
      this.user = response.data;
      this.$nextTick(() => {
        this.dirty = false;
        this.addressUpdate++;
      });
    },

    /**
     * Set user
     */
    async setUser() {
      const response = await this.fetchUser();
      this.user = response.data;
    }
  }
};
</script>

<style>
.v-input--switch .v-messages {
  display: none;
}
</style>
