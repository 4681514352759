<template>
  <v-dialog v-model="open" scrollable width="700">
    <v-card>
      <v-card-title class="primary white--text">
        <span class="subtitle-1">Badge drucken</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <v-alert type="info" outlined class="mb-6">
          Der Druckvorgang kann mehrere Minuten dauern.
        </v-alert>
        <v-select
          outlined
          dense
          v-model="mode"
          :items="modes"
          item-text="text"
          item-value="value"
          label="Typ"
        ></v-select>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()">
          Nein
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="print()"
          :disabled="!mode || loading"
        >
          Ja
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data: () => ({
    open: false,
    user: null,
    loading: false,
    mode: null,
    modes: [
      {
        text: "Mitarbeiter",
        value: "user"
      },
      {
        text: "Besucher",
        value: "visitor"
      },
      {
        text: "Archroma",
        value: "archroma"
      }
    ]
  }),

  methods: {
    /**
     * Close.
     */
    close() {
      this.open = false;
    },

    /**
     * Close.
     */
    print() {
      this.loading = true;
      this.$api.http
        .post(`backoffice/badge-printer/${this.mode}/${this.user.id}`)
        .then(() => {
          this.$toast.success("Der Badge wird nun gedruckt...");
          this.open = false;
          this.$emit("printed");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$toast.error("Der Badge konnte nicht gedruckt werden.");
        });
    }
  }
};
</script>
