<template>
  <v-dialog v-model="open" scrollable width="500">
    <v-form v-model="valid" @submit.prevent="save()">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="subtitle-1">Badge</span>
        </v-card-title>

        <v-card-text class="pt-6">
          <v-text-field
            dense
            outlined
            v-model="badgeNumber"
            label="Badgenummer"
            type="text"
            :maxlength="255"
            :rules="badgeNumberRules"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="close()">
            Abbrechen
          </v-btn>
          <v-btn color="primary" text @click="save()" :disabled="!valid">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data: () => ({
    open: false,
    valid: false,
    userId: null,
    badgeNumber: null,
    badgeNumberRules: [
      v => (!!v && /^\d+$/.test(v)) || "Ungültige Badgenummer."
    ]
  }),

  methods: {
    /**
     * Close.
     */
    close() {
      this.open = false;
    },

    /**
     * Close.
     */
    save() {
      this.$api.http
        .put(`backoffice/users/${this.userId}`, {
          badge_number: this.badgeNumber
        })
        .then(response => {
          this.open = false;
          this.$emit("saved");
        })
        .catch(error => {
          if (error.response.data && error.response.data.code) {
            const code = error.response.data.code;
            if (code === 102) {
              this.$toast.error(
                `Es existiert bereits ein Benutzer mit dieser Badgenummer.`
              );
            } else {
              this.$toast.error(
                `Die Badgenummer konnte nicht gespeichert werden.`
              );
            }
          } else {
            this.$toast.error(
              `Die Badgenummer konnte nicht gespeichert werden.`
            );
          }
        });
    }
  }
};
</script>
