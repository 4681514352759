<template>
  <v-dialog v-model="open" scrollable width="500">
    <v-card>
      <v-card-title class="primary white--text">
        <span class="subtitle-1">Mandant wechseln</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <v-select
          outlined
          dense
          :items="tenants"
          v-model="tenantId"
          label="Mandant"
          item-text="name"
          item-value="value"
          :rules="textRules"
        ></v-select>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="abort()">
          Abbrechen
        </v-btn>
        <v-btn color="primary" text @click="save()">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    tenants: [],
    open: false,
    user: null,
    tenantId: null,
    textRules: [v => !!v || "Muss ausgefüllt sein"]
  }),

  computed: {},

  watch: {
    open(value) {
      if (value && this.tenants.length === 0) {
        this.fetchTenants();
      }
    }
  },

  methods: {
    /**
     * Fetch tenants from server
     */
    fetchTenants() {
      this.$api.http
        .get(`backoffice/tenants?_per_page=99999`)
        .then(response => {
          this.tenants = response.data.data.map(tenant => {
            return {
              name: `${tenant.name}`,
              value: tenant.id
            };
          });
        });
    },

    /**
     * Delete entity.
     */
    save() {
      this.$api.http
        .put(`backoffice/users/${this.user.id}/tenant`, {
          tenant_id: this.tenantId
        })
        .then(response => {
          this.$emit("saved");
          this.open = false;
          this.tenantId = null;
          this.user = null;
        })
        .catch(error => {
          this.$toast.error("Mandant konnte nicht geändert werden.");
        });
    },

    /**
     * Abort.
     */
    abort() {
      this.open = false;
      this.tenantId = null;
      this.user = null;
    }
  }
};
</script>

<style lang="scss"></style>
