<template>
  <div class="catering-status-label">
    <v-chip
      v-if="user.wika_pass_number && user.is_member_wika"
      color="success"
      label
      :small="small"
      :large="large"
    >
      Verifiziert
    </v-chip>
    <v-chip
      v-else-if="user.wika_pass_number && !user.is_member_wika"
      color="warning"
      label
      :small="small"
      :large="large"
    >
      Wird geprüft
    </v-chip>
    <v-chip v-else color="default" label :small="small" :large="large">
      Keine Angabe
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
