<template>
  <div class="popover">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-x
      left
      nudge-left="20"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="info"
          dark
          small
          v-bind="attrs"
          v-on="on"
          fab
          elevation="0"
          icon
        >
          <v-icon>info</v-icon>
        </v-btn>
      </template>

      <v-card class="v-card-popover">
        <v-btn
          x-small
          fab
          elevation="0"
          color="white"
          class="btn-close"
          @click="menu = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text>
          {{ text }}
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "Popover",

  props: {
    text: {
      type: String,
      required: true
    }
  },

  data: () => ({
    menu: false
  })
};
</script>

<style lang="scss">
.v-card-popover {
  max-width: 300px;
  .v-card__text {
    color: black !important;
  }
  .btn-close {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
</style>
