<template>
  <div
    :class="['image-upload', image ? 'image-handle' : null]"
    :style="{
      backgroundImage: image ? `url(${image})` : '',
      '--ratio': (ratioH / ratioW) * 100 + '%'
    }"
  >
    <v-btn
      v-if="image && deletable"
      fab
      elevation="0"
      color="default"
      small
      class="float-right mt-2 mr-2"
      @click="openDeleteDialog()"
    >
      <v-icon>delete</v-icon>
    </v-btn>
    <v-btn
      fab
      elevation="0"
      color="default"
      small
      class="float-right mt-2 mr-2"
      @click="openUploadDialog()"
    >
      <v-icon>cloud_upload</v-icon>
    </v-btn>
    <v-btn
      v-if="image"
      fab
      elevation="0"
      color="default"
      x-small
      class="float-right mt-3 mr-2"
      @click="preview()"
    >
      <v-icon>preview</v-icon>
    </v-btn>
    <div v-if="!image" class="no-image">
      Kein Bild vorhanden
    </div>
    <image-upload-dialog
      :url="url"
      :ratio="ratioW / ratioH"
      @saved="$emit('changed')"
      ref="imageUploadDialog"
    />
    <image-delete-dialog
      :url="url"
      @deleted="$emit('changed')"
      ref="imageDeleteDialog"
    />
  </div>
</template>

<script>
import ImageUploadDialog from "./ImageUploadDialog";
import ImageDeleteDialog from "./ImageDeleteDialog";

export default {
  name: "ImageUpload",
  components: { ImageDeleteDialog, ImageUploadDialog },

  props: {
    value: {
      required: true
    },
    url: {
      type: String,
      required: true
    },
    ratioW: {
      type: Number,
      required: true
    },
    ratioH: {
      type: Number,
      required: true
    },
    deletable: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    image: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },

  methods: {
    /**
     * Open the avatar upload dialog.
     */
    openUploadDialog() {
      this.$refs.imageUploadDialog.open = true;
      this.$refs.imageUploadDialog.user = this.user;
    },

    /**
     * Open the avatar delete dialog.
     */
    openDeleteDialog() {
      this.$refs.imageDeleteDialog.open = true;
      this.$refs.imageDeleteDialog.user = this.user;
    },

    preview() {
      window.open(this.value, "_blank").focus();
    }
  }
};
</script>

<style scoped lang="scss">
.image-upload {
  border: 1px solid #dddddd;
  width: 100%;
  position: relative;
  background-size: 100%;

  .no-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: var(--ratio);
  }
}
</style>
