<template>
  <v-dialog v-model="open" scrollable width="500">
    <v-card>
      <v-card-title class="primary white--text">
        <span class="subtitle-1">Austritt</span>
      </v-card-title>

      <v-card-text class="pt-6">
        <p>Zu welchem Zeitpunkt erfolgt der Austritt?</p>
        <DatePicker v-model="resignsAt" label="Datum" clearable></DatePicker>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()">
          Abbrechen
        </v-btn>
        <v-btn color="primary" text @click="save()">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";
import DatePicker from "@/components/forms/DatePicker";

export default {
  components: {
    DatePicker
  },

  data: () => ({
    open: false,
    userId: null,
    resignsAt: null
  }),

  methods: {
    /**
     * Close.
     */
    close() {
      this.open = false;
    },

    /**
     * Close.
     */
    save() {
      this.$api.http
        .put(`backoffice/users/${this.userId}`, {
          resigns_at: this.resignsAt
            ? this.resignsAt.format("YYYY-MM-DD")
            : null
        })
        .then(response => {
          this.open = false;
          this.$emit("resigned");
        })
        .catch(error => {
          this.$toast.error("Der Benutzer konnte nicht gespeichert werden.");
        });
    }
  }
};
</script>
