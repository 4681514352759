<template>
  <v-dialog v-model="open" scrollable persistent width="800">
    <v-card>
      <v-card-title class="primary white--text">
        <span class="subtitle-1">Bild bearbeiten</span>
      </v-card-title>

      <v-card-text class="mt-3" :key="componentKey">
        <v-file-input
          v-model="file"
          accept="image/*"
          label="Bild"
          @change="setImage"
        ></v-file-input>
        <vue-cropper
          ref="cropper"
          :aspect-ratio="ratio"
          :src="imgSrc"
          :imgStyle="{ display: imgSrc ? 'block' : 'none' }"
        />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="abort()">
          Abbrechen
        </v-btn>
        <v-btn color="primary" text @click="save()" :disabled="loading">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "ImageUploadDialog",

  components: { VueCropper },

  props: {
    url: {
      type: String,
      required: true
    },
    ratio: {
      type: Number,
      default: 16 / 9
    }
  },

  data: () => ({
    open: false,
    loading: false,
    file: null,
    imgSrc: null,
    componentKey: 0
  }),

  watch: {
    open() {
      this.file = null;
      this.imgSrc = null;
      this.$forceUpdate();
      this.componentKey++;
    }
  },

  methods: {
    /**
     * Set uploaded image.
     *
     * @param file
     */
    setImage(file) {
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    /**
     *
     * Upload image
     *
     * @param imageData
     */
    uploadImage(imageData) {
      this.loading = true;
      let formData = new FormData();
      formData.append("image", imageData);
      this.$api.http
        .post(this.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          this.loading = false;
          this.$emit("saved");
          this.open = false;
        })
        .catch(error => {
          this.loading = false;
          this.$toast.error("Es gab ein Fehler beim Upload der Datei.");
        });
    },

    /**
     * Delete entity.
     */
    save() {
      let canvas = this.$refs.cropper.getCroppedCanvas();

      canvas.toBlob(blob => {
        let reader = new FileReader();
        this.uploadImage(blob);
      });
    },

    /**
     * Abort.
     */
    abort() {
      this.open = false;
    }
  }
};
</script>

<style lang="scss">
.preview {
  width: 100%;
}
</style>
