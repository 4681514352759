<template>
  <v-dialog v-model="open" scrollable width="500">
    <v-card>
      <v-card-title :class="[color, 'white--text']">
        <span class="subtitle-1">Bild löschen</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <p>
          Soll das Bild entfernt werden?
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="abort()">
          Nein
        </v-btn>
        <v-btn :color="color" text @click="deleteImage()">
          Ja
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    url: {
      type: String,
      required: true
    }
  },

  data: () => ({
    open: false,
    color: "error"
  }),

  methods: {
    /**
     * Delete image.
     */
    deleteImage() {
      this.$api.http
        .delete(this.url)
        .then(response => {
          this.open = false;
          this.$emit("deleted");
        })
        .catch(error => {
          this.$toast.error(`Das Bild konnte nicht gelöscht werden.`);
        });
    },

    /**
     * Abort.
     */
    abort() {
      this.open = false;
    }
  }
};
</script>
